const events = ['create_account']
export function trackEvent(event_name, data) {
    window.dataLayer = window.dataLayer || [];
    if(events.includes(event_name)){
        window.dataLayer.push({
            event: "gtmEvent",
            event_name: event_name,
            ...data
        })
    }
}