import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["previousEndodonticDate", "traumaHistoryDate"]

  connect() {
    this.toggleInitialState();
  }

  toggle(event) {
    const checkbox = event.target;

    if (checkbox.value === 'previous_endodontic_treatment') {
      this.previousEndodonticDateTarget.classList.toggle('d-none', !checkbox.checked);
    } else if (checkbox.value === 'history_of_trauma') {
      this.traumaHistoryDateTarget.classList.toggle('d-none', !checkbox.checked);
    }
  }

  toggleInitialState() {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.value === 'previous_endodontic_treatment' && checkbox.checked) {
        this.previousEndodonticDateTarget.classList.remove('d-none');
      } else if (checkbox.value === 'history_of_trauma' && checkbox.checked) {
        this.traumaHistoryDateTarget.classList.remove('d-none');
      }
    });
  }
}
