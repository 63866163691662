import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flatpickrTarget"];

  flatpickrTargetConnected(e) {
    let altInputClass = e.dataset.altInputClass || 'form-control normal-input';
    altInputClass += ' flatpickr-alt-input';
    flatpickr(e, {
      dateFormat: 'd/m/Y',
      allowInput: true,
      altInput: true,
      altInputClass: altInputClass,
      altFormat: 'm/d/Y',
      clickOpens: e.dataset.clickOpens || false
    });

    const flatpickrAltInput = e.closest('.flatpickr-group')?.querySelector('.flatpickr-alt-input')
    if (flatpickrAltInput) flatpickrAltInput.addEventListener('input', this.formatDateInput);
  }

  formatDateInput(event) {
    let input = event.target.value.replace(/\D/g, '');
    let formattedDate = '';

    if (input.length > 2 && input.length <= 4) {
      formattedDate = input.substring(0, 2) + '/' + input.substring(2);
    } else if (input.length > 4) {
      formattedDate = input.substring(0, 2) + '/' + input.substring(2, 4) + '/' + input.substring(4, 8);
    } else {
      formattedDate = input;
    }

    event.target.value = formattedDate;
  }

  flatpickrSnoozeTargetConnected(e) {
    flatpickr(e, {
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d',
      allowInput: true,
    });
  }
}
